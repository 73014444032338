import { state } from '@angular/animations';
import { async } from '@angular/core/testing';
import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { TagManagerService } from 'projects/new-portal/src/app/services/tag-manager.service';

@Component({
    selector: 'my-portal-footer-establishment',
    templateUrl: './establishment.component.html',
    styleUrls: ['./establishment.component.scss'],
})
export class EstablishmentFooterComponent implements OnInit {
    @Input() phoneWhatsapp : any;
    @Input() footerData: any;
    @Input() data: any;
    arrScripts: any = null;
    html: any;

    @ViewChildren('htmlContainer') container: any;

    constructor(
        private sanitize: DomSanitizer,
        private appDataService: AppDataService,
        private tagManagerService: TagManagerService
    ) {}

    ngOnInit(): void {
        const attrs: { [key: string]: RegExp }[] = [
            {
                async: /async/gm,
            },
            {
                src: /src="(.*?)"/g,
            },
            {
                type: /type="(.*?)"/g,
            },
        ];
        if (this.footerData[0].phone) {
            const phoneMasked: any = [];

            this.arrScripts = Object.keys(this.footerData[0].footer_scripts);

            for (const script of Object.keys(
                this.footerData[0].footer_scripts
            )) {
                if (this.footerData[0].footer_scripts[script]) {
                    let scriptContent = '';
                    let scriptAttrs = [];

                    for (const attr of attrs) {
                        const key = Object.keys(attr)[0];
                        const regex = attr[key];
                        const result = regex.exec(
                            this.footerData[0].footer_scripts[script]
                        );

                        if (result) {
                            scriptAttrs.push({
                                [key]: result[1] ? result[1] : !!result[0],
                            });
                        }
                    }

                    const scriptSrc = document.createElement('script');
                    scriptSrc.async = scriptAttrs[0]?.async as boolean;
                    scriptSrc.src = scriptAttrs[1]?.src as string;
                    scriptSrc.type = scriptAttrs[2]?.type as string;
                    document.body.appendChild(scriptSrc);

                    const replacedTag = this.footerData[0].footer_scripts[
                        script
                    ]
                        .replace(/<script[^>]*>/gi, '')
                        .replace(/<\/script>/gi, '');

                    scriptContent += replacedTag + '\n';

                    const scriptDOM = document.createElement('script');
                    scriptDOM.innerHTML = scriptContent;
                    document.body.appendChild(scriptDOM);

                    this.footerData[0].footer_scripts[script] =
                        this.sanitize.bypassSecurityTrustHtml(
                            this.footerData[0].footer_scripts[script]
                        );
                }
            }




        }

        let novoDiscagem = [];

        for (const phone of this.footerData[0].phone) {
            let numberTollFree = phone.substring(0, 4).includes('0800');

            let number;
            let type;
            let ddi;

            number = phone;

            if (!numberTollFree) {
                number = number.replace(/\D+/g, '').replace(/^0+/, '');
            }

            ddi = this.appDataService.hasNumberDdi(number);

            if (numberTollFree) {
                type = 'tollFree';
            } else if (number.length > (ddi ? 12 : 10)) {
                type = 'mobilePhone';
            } else {
                type = 'landlinePhone';
            }


            novoDiscagem.push({
                number,
                type,
                ddi
            })


            this.footerData[0].phone = novoDiscagem;
        }

        if (!this.footerData[0].whatsapp.number) {
            this.footerData[0].whatsapp.number = ''
        }

        if (this.footerData[0].whatsapp.number.length < 5) {
            this.footerData[0].whatsapp.number = ''
        }

        if (this.footerData[0].whatsapp) {
            const whatsNewNumber = this.footerData[0].whatsapp.number
                .replace(/\D+/g, '')
                .replace(/^0+/, '');
            const whatsVerification = whatsNewNumber.substring(0, 3).includes('55');

            this.footerData[0].whatsapp.number = whatsNewNumber;

            if (!whatsVerification && this.footerData[0].whatsapp.number.length <= 11 && this.footerData[0].whatsapp.number != '') {
                const newWhats = `55${this.footerData[0].whatsapp.number}`;
                this.footerData[0].whatsapp.number = newWhats;
            }
        }
    }

    triggerWhatsAppEvent(): void {
        this.tagManagerService.whatsAppEvent();
    }
}
