import { DialogShareComponent } from './dialog-share/dialog-share.component';
import { DialogVoucherComponent } from './dialog-voucher/dialog-voucher.component';
import { FormBuilder } from '@angular/forms';
import { AppDataService } from './../../../services/app-data.service';
import { Component, OnInit } from '@angular/core';


import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'my-portal-restricted-area-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrls: ['./fidelity.component.scss'],
})
export class RestrictedAreaFidelityComponent implements OnInit {
  dataFidelity: any;
  objHiddenPopover: any = {};
  currentPageVoucher: any;
  currentPageExtract: any;
  pageIndexVouchers: any = 0;
  pageIndexExtract: any = 0;
  user = this.documentService.getWindow()?.sessionStorage?.getItem('userData');
  userData: any = this.user ? JSON.parse(this.user) : null;

  constructor(
      private appData: AppDataService,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private documentService: DocumentService
  ) {}

    ngOnInit(): void {
      this.getData();
    }

    getData(pageVoucher?: any, pageExtract?: any): void {
      this.appData.getRestrictedAreaFidelity(pageVoucher, pageExtract).subscribe((response: any) => {
        this.dataFidelity = response.data;

        for (const item of this.dataFidelity.vouchers.data) {
          item.dateFormated = this.transformDate(item.expires);
        }

        for (const key in this.dataFidelity.vouchers.data) {
          this.objHiddenPopover[key] = {
            expirationPopover: false,
          };
        }
      });
    }
    transformDate(date: number) {
      const dateFormated = moment().add(date, 'days').format('DD/MM/YYYY');
      return dateFormated;
    }

    handleGenerateVoucher(value: number): void {
      this.appData.postRestrictedAreaGenerateVoucher(value).subscribe((response: any) => {
        // console.log(response);
      });
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(DialogVoucherComponent, {
        width: '300px',
        data: {},
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getData();
      });
    }

    onShareVoucherViaEmail(voucherId: number, type?: string): void {
      const dialogRef = this.dialog.open(DialogShareComponent, {
        width: '300px',
        data: {
          voucherId,
        },
      });

      dialogRef.afterClosed().subscribe(result => {});
    }

    changePageVouchers(page: any): void {
      this.pageIndexVouchers = page.pageIndex;
      this.getData(this.pageIndexVouchers + 1, this.pageIndexExtract + 1);
    }

    changePageExtract(page: any): void {
      this.pageIndexExtract = page.pageIndex;
      this.getData(this.pageIndexVouchers + 1, this.pageIndexExtract + 1);
    }

}


