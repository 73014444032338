import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InitializerService } from '../../services/initializer.service';
import { DocumentService } from '../../services/document.service';
import { TagManagerService } from '../../services/tag-manager.service';

@Component({
  selector: 'my-portal-btn-whats-app',
  templateUrl: './btn-whats-app.component.html',
  styleUrls: ['./btn-whats-app.component.scss']
})
export class BtnWhatsAppComponent implements OnInit,OnDestroy {
    private whatsAppInfoSubscription$!: Subscription;

    @Input() footerData: any;
    @Input() infoData : any
    phoneMsgWhatsApp :any;
    isMobile: boolean = this.initializerService.isMobileDevice();
    private routerSubscription!: Subscription;
    whatsAppNumber: string = ''
    whatsAppMessage: string = '';

    constructor(private router: Router, private initializerService: InitializerService, private documentService: DocumentService, private tagManagerService: TagManagerService) {
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.includes('sale') && this.isMobile) {
                    this.infoData.whatsapp.position.position = 'right';
                }

                if (this.router.url.includes('adicionais') || this.router.url.includes('search') ||this.router.url == '/' ) {
                    this.infoData.whatsapp.position.position = 'left';
                }
            }
        })
    }

    ngOnInit(): void {
        const sessionCart: any = JSON.parse(this.documentService.getWindow()?.localStorage.getItem('cart') as string);

        if (sessionCart?.count && this.isMobile && this.router.url.split('?')[0] === '/') {
            this.infoData.whatsapp.position.position = 'left';
        }

       this.handleWhatsApp();

        this.whatsAppInfoSubscription$ = this.initializerService.currentWhatsAppInfo.subscribe((whatsAppInfo: any) => {
                this.handleWhatsApp(whatsAppInfo);
        })
    }

    handleWhatsApp(genericPageWhatsApp?: any): void {
        const data = genericPageWhatsApp || this.footerData[0].whatsapp;

        if (!data.number) {
            data.number = '';
        }

        this.whatsAppMessage = data.message;
        let whats = data.number.replace(/\D+/g, '').replace(/^0+/, '');
        let whatsVerification = whats.substring(0, 4).includes('+55');

        if(!whatsVerification && this.footerData[0].whatsapp.number.length <= 11){
            const newWhats = `55${this.footerData[0].whatsapp.number}`
            whats = newWhats;
        }
        data.number = whats;
        this.whatsAppNumber = data.number;

        if (data.number.length <= 11) {
            const newWhats = data.number;
            data.number = newWhats;
            this.whatsAppNumber = data.number;
        }

        if(this.infoData.type > 3){
            const clientsArr: any[] = Object.values(this.infoData.whatsapp.message);
            const firstClient: any = clientsArr[0];
            this.phoneMsgWhatsApp = firstClient.text;

            if (genericPageWhatsApp) {
                this.phoneMsgWhatsApp = data.message;
            }
        }
    }

    triggerWhatsAppEvent(): void {
        this.tagManagerService.whatsAppEvent();
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();

        if (this.whatsAppInfoSubscription$) {
            this.whatsAppInfoSubscription$.unsubscribe();
        }
    }
}
