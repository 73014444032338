import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';
import { InitializerService } from './initializer.service';
import { Info } from '../../@types/establishment-data';
import { PromotionDetail, PromotionOffer } from '../../@types/tag-manager';

declare var gtag: any;
declare var fbq: any;

declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Injectable({
    providedIn: 'root',
})
export class TagManagerService {
    private typeSearch = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
    private userData = JSON.parse(this.documentService.getWindow()?.sessionStorage.getItem('userData')!);
    private establishmentData!: Info;
    private establishmentType: string;

    constructor(private documentService: DocumentService, private initializerService: InitializerService) {
        this.initializerService.currentData.subscribe((response: any) => {
            this.establishmentData = response;
        });

        this.establishmentType = this.typeSearch == '3' ? 'individual' : 'portal';
    }

    // Hotel individual e Portal
    searchEvent(data: any, url: string) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const isNotDestiny = url.includes('search');
            const eventName = isNotDestiny ? 'search' : 'search_destiny';

            const items = data.searchConfig.map((item: any) => {
                return {
                    number_adults: Number(item.adult),
                    number_children: item.ages ? item.ages.length : 0,
                };
            });

            const searchDataEvent = {
                event: eventName,
                hotel_info: {
                    name: data?.hotelInfo?.name,
                    id: data?.hotelInfo?.id,
                    category_name: this.establishmentType,
                    category_id: this.typeSearch,
                },
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
                event_details: {
                    category: 'Ecommerce',
                    action: 'Resultados de Pesquisa',
                },
                ecommerce: {
                    currency: 'BRL',
                    search_configuration: {
                        check_in: data?.searchConfig[0]?.checkin,
                        check_out: data?.searchConfig[0]?.checkout,
                        coupon: data?.searchConfig[0]?.coupon ?? null,
                        number_rooms: data?.searchConfig?.length,
                        items,
                    },
                },
            };
            const searchDataEventFBQ = {
                event: eventName,
                content_category: 'Ecommerce',
                content_name: 'Resultados de Pesquisa',
                currency: 'BRL',
                hotel_info: {
                    name: data?.hotelInfo?.name,
                    id: data?.hotelInfo?.id,
                    category_name: this.establishmentType,
                    category_id: this.typeSearch,
                },
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
                ecommerce: {
                    search_configuration: {
                        check_in: data?.searchConfig[0]?.checkin,
                        check_out: data?.searchConfig[0]?.checkout,
                        coupon: data?.searchConfig[0]?.coupon ?? null,
                        number_rooms: data?.searchConfig?.length,
                        items,
                    },
                },
            }

            this.pushToDatalayer(searchDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'Search', searchDataEventFBQ);
        }
    }

    // Adicionar ao Carrinho
    addToCartEvent(data: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const addToCartDataEvent = {
                event: 'add_to_cart',
                currency: 'BRL',
                value: data.preco,
                hotel_info: this.getHotelInfo(data),
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
                event_details: {
                    category: 'Ecommerce',
                    action: 'Adição de item no carrinho',
                },
                items: [
                    {
                        item_id: data?.tarifa_id,
                        item_name: data?.tarifa_nome,
                        affiliation: data?.canal_id,
                        coupon: data?.cupom,
                        discount: data?.incentivos?.total,
                        index: data?.index,
                        price: data?.preco,
                        quantity: 1,
                    },
                ],
            };

            const addToCartDataEventFBQ = {
                currency: 'BRL',
                content_category: 'Ecommerce',
                value: data?.preco,
                content_name: data?.tarifa_nome,
                content_ids: [data?.tarifa_id],
                hotel_info: this.getHotelInfo(data),
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
                contents: [
                    {
                        id: data?.tarifa_id,
                        item_name: data?.tarifa_nome,
                        affiliation: data?.canal_id,
                        coupon: data?.cupom,
                        discount: data?.incentivos?.total,
                        index: data?.index,
                        price: data?.preco,
                        quantity: 1,
                    },
                ],
            };

            this.pushToDatalayer(addToCartDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'AddToCart', addToCartDataEventFBQ);
        }
    }

    // Remover do Carrinho
    removeFromCartEvent(data: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const removeFromCartDataEvent = {
                event: 'remove_from_cart',
                currency: 'BRL',
                value: data.preco,
                hotel_info: this.getHotelInfo(data),
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
                event_details: {
                    category: 'Ecommerce',
                    action: 'Remoção de item do carrinho',
                },
                items: [
                    {
                        item_id: data?.tarifa_id,
                        item_name: data?.tarifa_nome,
                        affiliation: data?.canal_id,
                        coupon: data?.cupom,
                        discount: data?.incentivos?.total,
                        index: data?.index,
                        price: data?.preco,
                        quantity: 1,
                    },
                ],
            };

            this.pushToDatalayer(removeFromCartDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'RemoveFromCart', removeFromCartDataEvent);
        }
    }

    // Adicionar Extra
    additionalEvent(data: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const additionalDataEvent = {
                event: 'select_item',
                currency: 'BRL',
                event_details: {
                    category: 'Ecommerce',
                    action: 'Adição de um adicional',
                },
                items: [
                    {
                        item_id: data?.supplement?.id,
                        item_name: data?.supplement?.name,
                        discount: data?.supplement?.discount,
                        price: data?.supplement?.value,
                        quantity: data?.quantity,
                    },
                ],
            };

            this.pushToDatalayer(additionalDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'AddSupplement', additionalDataEvent);
        }
    }

    // Remover Extra
    removeAdditionalEvent(data: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const removeAdditionalDataEvent = {
                event: 'select_item',
                currency: 'BRL',
                event_details: {
                    category: 'Ecommerce',
                    action: 'Remoção de um adicional',
                },
                items: [
                    {
                        item_id: data?.supplement?.id,
                        item_name: data?.supplement?.name,
                        discount: data?.supplement?.discount,
                        price: data?.supplement?.value,
                        quantity: data?.quantity,
                    },
                ],
            };

            this.pushToDatalayer(removeAdditionalDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'RemoveSupplement', removeAdditionalDataEvent);
        }
    }

    // Início Checkout
    beginCheckoutEvent(cart: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const arrayDeItems = [];
            const contentIds = [];
            const contents = [];
            let responsible: any;

            for (const hotel in cart.hotels) {
                for (const quarto of cart.hotels[hotel].items) {
                    arrayDeItems.push({
                        item_id: quarto?.quarto_id,
                        item_name: quarto?.quarto_nome,
                        establishment_id: cart?.hotels[hotel]?.id,
                        establishment_name: cart?.hotels[hotel]?.name,
                        additionals: quarto?.suplementos?.items,
                    });

                    contentIds.push(quarto?.quarto_id);

                    contents.push({
                        id: quarto?.quarto_id,
                        quantity: 1,
                        item_id: quarto?.quarto_id,
                        item_name: quarto?.quarto_nome,
                        establishment_id: cart?.hotels[hotel]?.id,
                        establishment_name: cart?.hotels[hotel]?.name,
                        additionals: quarto?.suplementos?.items,
                    });
                }
                responsible = cart?.hotels[hotel]?.responsavel;
            }

            const beginCheckoutDataEvent = {
                event: 'begin_checkout',
                currency: 'BRL',
                value: cart?.total,
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: responsible?.nome ? responsible.nome : this.userData?.name ?? null,
                    last_name: responsible?.sobrenome ?? null,
                    user_email: responsible?.email ? responsible.email : this.userData?.email ?? null,
                    phone: responsible?.telefone ?? responsible.telefone,
                },
                event_details: {
                    category: 'Ecommerce',
                    action: 'Inicialização do checkout',
                },
                items: arrayDeItems,
            };

            const beginCheckoutDataEventFBQ = {
                content_category: 'Ecommerce',
                content_ids: contentIds,
                content_name: 'Inicialização do checkout',
                contents,
                currency: 'BRL',
                num_items: contents.length,
                value: cart?.total,
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
            }

            this.pushToDatalayer(beginCheckoutDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'InitiateCheckout', beginCheckoutDataEventFBQ);
        }
    }

    // Confirmação Dados de Pagamento
    addPaymentInfoEvent(hotel: any, payment: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const arrayDeItems = [];
            let totalTaxes = 0;
            for (const item of hotel.items) {
                if (item?.taxas) {
                    for (const taxa of item.taxas.tax) {
                        totalTaxes += taxa.value;
                    }
                }
            }

            const addPaymentInfoDataEvent = {
                event: 'add_payment_info',
                currency: 'BRL',
                value: payment?.price,
                total_taxes: totalTaxes,
                discount: hotel?.discount,
                payment_type: payment?.name,
                event_details: {
                    category: 'Ecommerce',
                    action: 'Adição de tipo de pagamento',
                },
            };

            const addPaymentInfoDataEventFBQ = {
                content_category: 'Ecommerce',
                content_name: 'Adição de tipo de pagamento',
                currency: 'BRL',
                value: payment?.price,
                total_taxes: totalTaxes,
                discount: hotel?.discount,
                payment_type: payment?.name,
            }

            this.pushToDatalayer(addPaymentInfoDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'AddPaymentInfo', addPaymentInfoDataEventFBQ);
        }
    }

    // Compra
    purchaseEvent(finishSale: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            let responsibleEmail: string | null = null
            const arrayDeItems = [];
            const contentIds = [];
            const contents = [];
            let totalTaxes = 0;
            let transaction_id = null;

            for (const hotel in finishSale.hotels) {
                for (const quarto in finishSale.hotels[hotel].items) {
                    arrayDeItems.push({
                        item_id: finishSale?.hotels[hotel]?.items[quarto]?.quarto_id,
                        item_name: finishSale?.hotels[hotel]?.items[quarto]?.quarto_nome,
                        sale_id: finishSale?.hotels[hotel]?.sales?.sales[quarto]?.sale_id,
                        coupon: finishSale?.hotels[hotel]?.items[quarto]?.cupom,
                        price: finishSale?.hotels[hotel]?.items[quarto]?.preco,
                        establishment: finishSale?.hotels[hotel]?.name,
                        establishment_id: finishSale?.hotels[hotel]?.id,
                        additionals: finishSale?.hotels[hotel]?.items[quarto]?.suplementos?.items,
                    });

                    if (finishSale?.hotels[hotel].items[quarto].taxas) {
                        totalTaxes += finishSale?.hotels[hotel]?.items[quarto]?.taxas?.total;
                    }

                    contentIds.push(finishSale?.hotels[hotel]?.items[quarto]?.quarto_id);

                    contents.push({
                        id: finishSale?.hotels[hotel].items[quarto].quarto_id,
                        quantity: 1,
                        item_id: finishSale?.hotels[hotel]?.items[quarto]?.quarto_id,
                        item_name: finishSale?.hotels[hotel]?.items[quarto]?.quarto_nome,
                        sale_id: finishSale?.hotels[hotel]?.sales?.sales[quarto]?.sale_id,
                        coupon: finishSale?.hotels[hotel]?.items[quarto]?.cupom,
                        price: finishSale?.hotels[hotel]?.items[quarto]?.preco,
                        establishment: finishSale?.hotels[hotel]?.name,
                        establishment_id: finishSale?.hotels[hotel]?.id,
                        additionals: finishSale?.hotels[hotel]?.items[quarto]?.suplementos?.items,
                    });
                }
                transaction_id = finishSale.hotels[hotel].sales.principal_sale;
                responsibleEmail = finishSale?.hotels[hotel]?.responsavel?.email;
            }

            const purchaseDataEvent = {
                event: 'purchase',
                currency: 'BRL',
                value: finishSale?.total,
                total_taxes: totalTaxes,
                transaction_id,
                hotel_info: {
                    category_name: this.establishmentType,
                    category_id: this.typeSearch,
                },
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ? this.userData?.email : responsibleEmail,
                },
                event_details: {
                    category: 'Ecommerce',
                    action: 'Compra realizada',
                },
                items: arrayDeItems,
            };

            const purchaseDataEventFBQ = {
                content_category: 'Ecommerce',
                content_name: 'Compra realizada',
                currency: 'BRL',
                value: finishSale?.total,
                total_taxes: totalTaxes,
                transaction_id,
                contents,
                content_ids: contentIds,
                hotel_info: {
                    category_name: this.establishmentType,
                    category_id: this.typeSearch,
                },
                user_data: {
                    is_logged_in: !!this.userData?.id,
                    first_name: this.userData?.name ?? null,
                    user_email: this.userData?.email ?? null,
                },
            };

            this.pushToDatalayer(purchaseDataEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'Purchase', purchaseDataEventFBQ);
        }
    }

    // Detalhe Pacote / Promoções
    selectPromotion(promotionItems: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const viewPromotionsListEvent = {
                event: 'view_item_list',
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Listagem de pacotes',
                },
                items: promotionItems?.map((item: any) => ({
                    item_id: item.id,
                    item_name: item.title,
                    discount: item.discount,
                    price: item.lowest_rate.price,
                }))
            };

            this.pushToDatalayer(viewPromotionsListEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'SelectPromotion', viewPromotionsListEvent);
        }
    }

    // Listagem Pacotes / Promoções
    viewPromotion(data: PromotionDetail) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const viewPromotionsListEvent = {
                event: 'view_promotion',
                promotion_id: data?.id,
                promotion_name: data?.title,
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Detalhes de um pacote',
                },
                items: [
                    {item_id: data?.id, item_name: data?.title}
                ],
            };

            this.pushToDatalayer(viewPromotionsListEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'ViewPromotion', viewPromotionsListEvent);
        }
    }

    // Ação de clique em uma das ofertas do detalhe do pacote
    viewOfferEvent(promotion: PromotionDetail, offer: PromotionOffer) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const offerItem = {
                item_id: offer?.id,
                item_name: offer?.name,
                discount: offer?.discount,
                price: offer?.price,
            };

            const viewOfferEvent = {
                event: 'select_promotion',
                promotion_id: promotion?.id,
                promotion_name: promotion?.title,
                items: [offerItem],
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Clique em uma oferta da página de um pacote',
                },
            };

            this.pushToDatalayer(viewOfferEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'ViewOfferEvent', viewOfferEvent);
        }
    }

    // Ver carrinho
    viewCartEvent(cart: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const items: any[] = [];

            for (const id of Object.keys(cart.hotels)) {
                cart?.hotels[id]?.items.forEach((item: any) => {
                    items.push({
                        ...item, item_id: item.quarto_id, item_name: item.quarto_nome,
                    });
                });
            }

            const viewCartEvent = {
                event: 'view_cart',
                currency: 'BRL',
                value: cart?.total,
                items,
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Clique em vizualizar carrinho',
                },
            };

            this.pushToDatalayer(viewCartEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'ViewCartEvent', viewCartEvent);
        }
    }

    viewItemEvent(data: any) {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const viewItemEvent = {
                event: 'view_item',
                currency: 'BRL',
                value: data?.price,
                item: {
                    item_id: data?.id,
                    item_name: data?.name,
                    price: data?.price,
                },
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Clique em mais detalhes',
                },
            };
            this.pushToDatalayer(viewItemEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'ViewItemEvent', viewItemEvent);
        }
    }

    whatsAppEvent() {
        if (this.hasTagManager() || this.hasGoogleAnalitycs() || this.hasFacebookPixel()) {
            const whatsAppClickEvent = {
                event: 'whatsapp',
                eventDetails: {
                    category: 'Ecommerce',
                    action: 'Clique em whatsapp',
                },
            };
            this.pushToDatalayer(whatsAppClickEvent);

            if (((<any> window)?.fbq) && this.hasFacebookPixel())
            fbq('track', 'WhatsApp', whatsAppClickEvent);
        }
    }

    // Verificar se o Tag Manager está ativo

    hasTagManager() {
        return this.establishmentData?.marketing?.google_tag_manager;
    }

    hasGoogleAnalitycs() {
        return this.establishmentData?.marketing?.google_analytics?.geral;
    }

    hasFacebookPixel() {
        return this.establishmentData?.marketing?.facebook_pixelid;
    }

    // Verificar se existe DataLayer
    hasDatalayer() {
        return window.dataLayer;
    }

    // Verificar se existe DataLayer
    pushToDatalayer<T>(data: T) {
        if (this.hasDatalayer()) {
            window.dataLayer.push(data);
        }
    }

    getHotelInfo(data: any) {
        return {
            name: data?.estabelecimento,
            id: data?.pessoa_id,
            category_name: this.establishmentType,
            category_id: this.typeSearch,
        };
    }
}
