import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import { environment } from 'projects/new-portal/src/environments/environment';
import { InitializerService } from '../../services/initializer.service';
import { TagManagerService } from '../../services/tag-manager.service';

@Component({
    selector: 'my-portal-list-promotions',
    templateUrl: './list-promotions.component.html',
    styleUrls: ['./list-promotions.component.scss']
})
export class ListPromotionsComponent implements OnInit {

    @Input() packageList: any;
    load: boolean = false;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(
        private appService: AppDataService,
        private initializerService: InitializerService,
        private tagManagerService: TagManagerService
        ) {}

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        for (const item of this.packageList.items) {
            item.slug = this.appService.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        }

        this.tagManagerService.selectPromotion(this.packageList?.items);
    }

}
