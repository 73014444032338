<div class="footer-portal" [ngClass]="{'no-address': data?.hide_address}">
    <div class="container-hotels">
        <ng-template #listHotelsFooter [ngTemplateOutlet]="listHotelsFooter" *ngFor="let hotel of footerData; let ind = index;">
            <div class="hotel" [ngClass]="{'active-hotel ' : active === ind}" matRipple (click)="changeFooter(ind);">
                <h6>{{ hotel.name }}</h6>
                <span *ngIf="!data?.hide_stars">
                    <i *ngFor="let star of [0,1,2,3,4]; let i = index" [ngClass]="{'star': (i+1) <= hotel.star}"
                       class="material-icons notranslate">star</i>
                </span>
            </div>
        </ng-template>
    </div>
    <div class="content-hotel">
        <div>
            <div *ngIf="footerPhones?.length > 0" class="container-footer">
                <div class="icon-footer">
                    <i class="material-icons notranslate">phone</i>
                </div>

                <div class="box">
                    <a *ngFor="let phone of footerPhones" href="tel:{{phone.number}}">
                        <p>
                            <span *ngIf="phone.type === 'landlinePhone'">
                                Telefone: {{ phone.number | mask: (phone.ddi ? '+00 (00) 0000-0000' : '(00) 0000-0000')}}
                            </span>
                            <span *ngIf="phone.type === 'mobilePhone' ">
                                Telefone: {{ phone.number | mask: (phone.ddi ? '+00 (00) 0 0000-0000' : '(00) 0 0000-0000')}}
                            </span>
                            <span *ngIf="phone.type === 'tollFree'">
                                Telefone: {{ phone.number | mask: '0000 000 0000'}}
                            </span>
                        </p>
                    </a>
                    <a *ngIf="footerData[active].whatsapp" href="https://api.whatsapp.com/send?phone={{ footerData[active].whatsapp.number}}{{phoneWhatsapp?.message[footerData[active]?.id].text ? '&text=' + phoneWhatsapp?.message[footerData[active]?.id]?.text : ''}}" target="_blank" (click)="triggerWhatsAppEvent()">
                        <p>
                            <span *ngIf="footerData[active].whatsapp.number.length >= 13 && footerData[active]" >
                                Whatsapp: {{ footerData[active].whatsapp.number | mask: '+00 (00) 0 0000-0000'}}
                            </span>

                            <span *ngIf="footerData[active].whatsapp.number.length < 13 && footerData[active]?.whatsapp.number.length > 5 && footerData[active]" >
                                Whatsapp: {{ footerData[active].whatsapp.number | mask: '+00 (00) 0000-0000'}}
                            </span>
                        </p>
                    </a>
                </div>
            </div>
            <div class="container-footer" *ngIf="footerData[active]?.email">
                <div class="icon-footer">
                    <i class="material-icons notranslate">mail_outline</i>
                </div>
                <div class="box">
                    <p>
                        <a *ngIf="footerData[active].email" href="mailto:{{ footerData[active].email }}">
                            {{ footerData[active].email }}
                        </a>
                    </p>
                </div>
            </div>

            <div class="container-footer" *ngIf="!data?.hide_address">
                <div class="icon-footer">
                    <i class="material-icons notranslate">location_on</i>
                </div>
                <div class="box">
                    <p>
                        {{ footerData[active].address }}, {{ footerData[active]?.district ? footerData[active]?.district + ' | ' : '' }}
                        {{ footerData[active].city }} | {{ footerData[active].state }} | {{ footerData[active].country }}
                        {{ footerData[active].cep ? '| CEP: ' + footerData[active].cep : '' }}
                    </p>
                </div>
            </div>

            <div class="container-footer social">
                <div class="social-networking">
                    <a *ngFor="let social of footerData[active].social_network" [href]="social.url" target="_blank">
                        <button [className]="social.name | lowercase" [attr.aria-label]="social.name">
                            <i class="{{'icon-' + social.name | lowercase }} notranslate"></i>
                        </button>
                    </a>
                </div>
            </div>

            <div class="cnpj">
                <h6>{{ footerData[active].name }}</h6>
                <div *ngIf="footerData[active]?.doc?.number && !data.hide_cnpj">CNPJ: {{ footerData[active]?.doc?.number }}</div>
            </div>
        </div>
    </div>
    <div class="map" *ngIf="!data?.hide_address">
        <iframe loading="lazy" title="Mapa de Localização" *ngIf="footerData[active].location_map" [src]="footerData[active].location_map | sanitize" allowfullscreen="" aria-hidden="false" frameborder="0" height="300"
                style="border:0;" tabindex="0" width="100%"></iframe>
    </div>
</div>
