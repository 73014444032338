<section id="faithfulness" class="faithfulness pt-5 pb-5" *ngIf="dataFidelity && this.userData?.hotelHasfidelity">
    <div class="container">
        <div class="row title_section no-gutters">
            <h6>Fidelidade</h6>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-released rounded">
                    <p class="text-light">Créditos liberados</p>
                    <div class="divider"></div>
                    <p class="value text-light">{{dataFidelity?.cards.released | currency}}</p>
                    <button (click)="openDialog()" class="btn">Gerar cupom</button>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-release rounded">
                    <p class="text-dark">Créditos a liberar</p>
                    <div class="divider"></div>
                    <p class="value">{{dataFidelity?.cards?.to_release | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-used rounded">
                    <p class="text-dark">Créditos utilizados</p>
                    <div class="divider"></div>
                    <p class="value text-danger">{{dataFidelity?.cards.used | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-expired rounded">
                    <p class="text-dark">Créditos Expirados</p>
                    <div class="divider"></div>
                    <p class="value text-danger">{{dataFidelity?.cards?.expired | currency}}</p>
                </div>
            </div>

        </div>

        <div class="table-list">
            <h5 class="title">Meus cupons</h5>
            <div class="table">
                <div class="table-tr title_table">
                    <div class="table-td">
                        <h6>Código dos cupons</h6>
                    </div>
                    <div class="table-td">
                        <h6>Expiração</h6>
                    </div>
                    <div class="table-td">
                        <h6>Valor</h6>
                    </div>
                    <div class="table-td share">
                        <h6>Compartilhar</h6>
                    </div>
                </div>

                <div class="table-tr" *ngFor="let voucher of dataFidelity?.vouchers?.data; let ind = index"
                    [ngClass]="{'disabled-vouher': !voucher.avaliable}">
                    <div class="table-td">
                        <label>Código dos cupons</label>
                        <p>{{voucher.code}}</p>
                    </div>
                    <div class="table-td">
                        <div class="center-table expiracao">
                            <label>Expiração</label>
                            <div class="d-flex">
                                <p>{{ voucher.dateFormated }}</p>
                                <div class="table-popover"
                                    (click)="objHiddenPopover[ind].expirationPopover = !objHiddenPopover[ind].expirationPopover">
                                    <i class="material-icons notranslate">info</i>
                                    <my-portal-popover [hidden]="!objHiddenPopover[ind].expirationPopover"
                                        [type]="'restriction'" [content]="['expira em ' + voucher.expires + ' dias']">
                                    </my-portal-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-td hidden">
                        <label>Valor</label>
                        <p>{{voucher.value | currency}}</p>
                    </div>
                    <div class="table-td share hidden">
                        <label>Compartilhar</label>
                        <p>
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <i class="material-icons-outlined notranslate">share</i>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button [cdkCopyToClipboard]="voucher.code" [cdkCopyToClipboardAttempts]="voucher.code"
                                    mat-menu-item>
                                    <span>Copiar cupom</span>
                                </button>
                                <a [href]="'https://web.whatsapp.com/send?text=Código%20do%20cupom:%20' + voucher.code"
                                    target="_blank" mat-menu-item>
                                    <span>Enviar pelo WhatsApp</span>
                                </a>
                                <button (click)="onShareVoucherViaEmail(voucher.id)" mat-menu-item>
                                    <span>Enviar por email</span>
                                </button>
                            </mat-menu>
                        </p>
                    </div>
                    <div class="valor_compartilhar d-block d-md-none">
                        <div class="d-flex">
                            <div class="table-td">
                                <label>Valor</label>
                                <p>{{voucher.value | currency}}</p>
                            </div>

                            <div class="table-td share d-flex justify-content-center align-items-center">
                                <p>
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <i class="material-icons-outlined notranslate">share</i>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button [cdkCopyToClipboard]="voucher.code"
                                            [cdkCopyToClipboardAttempts]="voucher.code" mat-menu-item>
                                            <span>Copiar cupom</span>
                                        </button>
                                        <a [href]="'https://web.whatsapp.com/send?text=Código%20do%20cupom:%20' + voucher.code"
                                            target="_blank" mat-menu-item>
                                            <span>Enviar pelo WhatsApp</span>
                                        </a>
                                        <button (click)="onShareVoucherViaEmail(voucher.id)" mat-menu-item>
                                            <span>Enviar por email</span>
                                        </button>
                                    </mat-menu>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-container">
            <p>Página</p>
            <mat-paginator [length]="dataFidelity?.vouchers?.total" [hidePageSize]="true"
                [pageIndex]="currentPageVoucher" [pageSize]="dataFidelity?.vouchers?.per_page"
                (page)="changePageVouchers($event)" aria-label="Selecione a página">
            </mat-paginator>
        </div>

        <div class="table-list">
            <h5 class="title">Extrato</h5>
            <div class="table">
                <div class="table-tr title_table">
                    <div class="table-td">
                        <h6>Data</h6>
                    </div>
                    <div class="table-td">
                        <h6>Expiração</h6>
                    </div>
                    <div class="table-td">
                        <h6>Valor</h6>
                    </div>
                    <div class="table-td">
                        <h6>Id</h6>
                    </div>
                    <div class="table-td">
                        <h6>Reserva</h6>
                    </div>
                    <div class="table-td">
                        <h6>Estabelecimento</h6>
                    </div>
                    <div class="table-td">
                        <h6>Produto</h6>
                    </div>
                    <div class="table-td">
                        <h6>Status</h6>
                    </div>
                </div>

                <div class="table-tr" *ngFor="let extract of dataFidelity?.extract.data">
                    <div class="table-td">
                        <label>Data</label>
                        <p>{{extract.date | date: 'dd/MM/yy'}}</p>
                    </div>
                    <div class="table-td">
                        <label>Expiração</label>
                        <p>{{extract.date | date: 'dd/MM/yy'}}</p>
                    </div>
                    <div class="table-td">
                        <label>Valor</label>
                        <p [ngClass]="{'paid': extract.type.id === 1, 'canceled': extract.type.id === 2}">
                            {{extract.type.id === 1 ? '+' : '-'}} {{extract.value | currency}}
                        </p>
                    </div>
                    <div class="table-td">
                        <label>ID</label>
                        <p>{{extract.id}}</p>
                    </div>
                    <div class="table-td">
                        <label>Reserva</label>
                        <p>{{extract.reserve}}</p>
                    </div>
                    <div class="table-td">
                        <label>Estabelecimento</label>
                        <p>{{extract.establishment}}</p>
                    </div>
                    <div class="table-td">
                        <label>Produto</label>
                        <p>Hospedagem</p>
                    </div>
                    <div class="table-td">
                        <label>Status</label>
                        <p>Crédito</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-container">
            <p>Página</p>
            <mat-paginator [length]="dataFidelity?.extract?.total" [hidePageSize]="true"
                [pageIndex]="currentPageExtract" [pageSize]="dataFidelity?.extract.per_page"
                (page)="changePageExtract($event)" aria-label="Selecione a página">
            </mat-paginator>
        </div>
    </div>
</section>
