<div class="container-toolbar" [ngClass]="{
    'model-3': dataHeader?.header?.type > 1,
    'toolbar-position-absolute': position,
    'portal': dataHeader?.type === 6
}">
    <mat-toolbar class="pt-1 pb-1" [ngClass]="{'logo-white-no-border': dataHeader?.header?.searchType == 3 || dataHeader?.header?.searchType == 2, 'logo-white-border-radius': dataHeader?.header?.searchType == 1}">
        <mat-toolbar-row>
            <div class="container">
                <div class="row align-items-center justify-content-between">

<!--                    <pre>{{ dataHeader?.header | json }}</pre>-->
                    <div class="logo-container centered"
                         [ngClass]="
                         {
                         'logo-white-no-border': dataHeader?.header?.type_menu == 1 && dataHeader?.header?.searchType == 2,
                         'logo-white-border-radius': dataHeader?.header?.type_menu == 1 && dataHeader?.header?.searchType == 1,
                         'logo-transparent': dataHeader?.header?.type_menu == 3
                         }">
                        <button *ngIf="!dataHeader?.hide_menu_mobile"
                                (click)="toggleRightSidenav()"
                                class="d-block d-md-none d-lg-none d-xl-none"
                                mat-icon-button>
                            <mat-icon>menu</mat-icon>

                        </button>
                        <div *ngIf="dataHeader?.hide_menu_mobile">
                            <mat-icon class="d-none">menu</mat-icon>
                        </div>

                        <a [href]="'https://' + dataHeader.logo_link">
                            <figure>
                                <img
                                    class="img-fluid"
                                    [ngClass]="{'resize': hiddenSlide}"
                                    src="{{ dataHeader?.logo ? imgServer + 'width=' + 157 + imgServerUrlMaxQuality + dataHeader?.logo : 'https://images.focomultimidia.com/curl/motor_reserva/images/logo.png' }}"
                                    width="157" height="auto" alt="logo">
                            </figure>
                        </a>
                    </div>
                    <div *ngIf="!dataHeader.only_motor" class="col-md-9 pl-0">
                        <div class="d-none d-md-flex d-lg-flex d-xl-flex">
                            <div class="dropdown" *ngFor="let menu of dataHeader?.menus">
<!--                                <button-->
<!--                                    mat-button-->
<!--                                    [routerLink]="menu.url">-->
<!--                                    {{ menu.title }}-->
<!--                                    <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>-->
<!--                                </button>-->


                                <a [routerLink]="menu.url" *ngIf="menu.target === '_self'">
                                    <button
                                        mat-button>
                                        {{ menu.title }}
                                        <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>
                                    </button>
                                </a>

                                <a [href]="menu.url" [target]="menu.target" *ngIf="menu.target === '_blank'">
                                    <button
                                        mat-button>
                                        {{ menu.title }}
                                        <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>
                                    </button>
                                </a>


                                <div class="dropdown-content" *ngIf="menu.submenu.length > 0">
                                    <a *ngFor="let sub of menu.submenu" [href]="sub.url" [target]="sub.target">{{sub.title}}</a>
                                </div>
                            </div>
                            <div class="restricted" *ngIf="hiddenSlide">
                                <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button (click)="openDialogLoginType(userLogged)">
                                    Minha conta
                                </button>
                            </div>
                        </div>
                    </div>
                    <div></div>

                    <div *ngIf="dataHeader?.menus.length < 7" class="col-md-3 pr-0">
                        <div class="d-none d-md-flex d-lg-flex d-xl-flex justify-content-end">
                            <div class="restricted">
                                <button mat-button *ngIf="dataHeader.only_motor">
                                    <a [href]="dataHeader.url_site" target="_blank">
                                        Voltar ao site
                                    </a>
                                </button>

                                <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button (click)="openDialogLoginType(userLogged)">
                                    Minha conta
                                    <!-- {{ userLogged ? 'Minha conta' : 'Área Restrita' }} -->
                                </button>

                                <button *ngIf="!dataHeader.only_motor && !dataHeader.hide_contact" (click)="handleRouteClick('contato')" mat-button>
                                    Contato
                                </button>

                                <div class="my-searches" *ngIf="!dataHeader?.hide_my_search">
                                    <button mat-button class="trigger" (click)="showSearches = !showSearches">
                                        Minhas buscas
                                    </button>

                                    <my-portal-my-searches *ngIf="showSearches"></my-portal-my-searches>

                                    <!-- <div class="back-drop" *ngIf="showSearches"  (click)="showSearches = !showSearches"></div> -->
                                </div>
                                <div class="container-translate d-flex align-items-center" *ngIf="dataHeader?.translate && !getScreenWidth">
                                    <my-portal-translate [dataHeader]="dataHeader"></my-portal-translate>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="dataHeader?.menus.length >= 7" class="d-none d-md-flex align-items-center " >
                        <div class="dropdown dropdown-navbar" *ngIf="!dataHeader?.hide_my_search || !dataHeader.hide_contact || dataHeader.restrited_area">
                            <div class="d-flex">
                                <a class="d-flex justify-content-center align-items-center">
                                    Minha conta
                                    <i translate="no" class="material-icons-outlined notranslate">expand_more</i>
                                </a>
                            </div>
                            <div class="dropdown-content">
                                <button mat-button *ngIf="dataHeader.only_motor">
                                    <a [href]="dataHeader.url_site" target="_blank">
                                        Voltar ao site
                                    </a>
                                </button>

                                <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button (click)="openDialogLoginType(userLogged)">
                                    Minha conta
                                    <!-- {{ userLogged ? 'Minha conta' : 'Área Restrita' }} -->
                                </button>
                                <button *ngIf="!dataHeader.only_motor && !dataHeader.hide_contact" (click)="handleRouteClick('contato')" mat-button>
                                    Contato
                                </button>

                                <div class="my-searches" *ngIf="!dataHeader?.hide_my_search">
                                    <button mat-button class="trigger" (click)="showSearches = !showSearches" class="w-100">
                                        Minhas buscas
                                    </button>

                                    <my-portal-my-searches [veritifactionVert]="this.dataHeader?.menus.length >= 7" *ngIf="showSearches"></my-portal-my-searches>

                                    <!-- <div class="back-drop" *ngIf="showSearches"  (click)="showSearches = !showSearches"></div> -->
                                </div>

                            </div>
                        </div>
                        <div class="container-translate d-flex align-items-center" *ngIf="dataHeader?.translate && !getScreenWidth">
                            <my-portal-translate [dataHeader]="dataHeader"></my-portal-translate>
                        </div>
                    </div>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
